import React, {PropsWithChildren, useState} from 'react';
import './App.css';

import {MuiThemeProvider, createMuiTheme, createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {green, orange} from '@material-ui/core/colors';

import Typography from '@material-ui/core/Typography';
import {
  Button,
  Container,
  FormControl,
  FormLabel, Grid,
  Link,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  SearchRounded,
  NotificationImportantRounded,
  AccountCircleRounded,
  PlayArrowRounded,
  SkipNextRounded,
  VolumeUpRounded,
  SubtitlesRounded,
  AirplayRounded,
  BrandingWatermarkRounded,
  FullscreenRounded,
  ThumbUpAltRounded,
  ThumbDownAltRounded,
  ShareRounded,
  CloudDownloadRounded,
  VideocamRounded,
} from '@material-ui/icons';

let isMVP = false;

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: green,
  },
});

interface IVideo {
  hash: string;
  thumbnail: string;
  title: string;
  channel: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      flexDirection: 'column',
    },
    gridTile: {
      width: 320,
      marginRight: theme.spacing(2),
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    gridTileOverlay: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%)',
      padding: `0 ${theme.spacing(2)}px`,
      color: theme.palette.common.white,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      flexDirection: 'row',
      display: 'flex',
      listStyle: 'none',
      overflowY: 'scroll',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    dropzone: {
      borderWidth: '4px',
      borderStyle: 'dashed',
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      padding: `${theme.spacing(4)}px 0`,
      textAlign: 'center',
      margin: `${theme.spacing(4)}px 0`,
      color: theme.palette.text.primary,
    },
    network: {
      flexDirection: 'row',
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      height: `calc(103px + ${theme.spacing(4)}px + ${theme.spacing(2)}px)`,
      margin: `${theme.spacing(4)}px 0`,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: orange[100],
      alignItems: 'flex-end',
    },
    networkStats: {
      position: 'absolute',
      top: '16px',
      left: '32px',
      opacity: '0.5',
    },
    networkGraphBar: {
      width: `calc((1% - ${theme.spacing(1)}px) / 4)`,
      minWidth: '3px',
      backgroundColor: orange[400],
      marginLeft: theme.spacing(.5),
    },
    channelBanner: {
      height: 200,
      width: '100%',
      marginBottom: '16px',
    },
    userBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingBottom: theme.spacing(1),
      width: '100%',
      cursor: 'pointer',
    },
    userBlockAvatar: {
      flex: 0,
      marginRight: theme.spacing(1),
    },
    userBlockTitle: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    videoContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      position: 'relative',
      background: 'black',
    },
    videoPlayer: {
      margin: '0 auto',
      width: '64vw',
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    videoControls: {
      background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 80%, rgba(0,0,0,0) 100%)',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.primary.dark,
    },
    videoProgressWrapper: {
      backgroundColor: 'rgba(66,66,66,.5)',
      position: 'absolute',
      left: theme.spacing(1),
      right: theme.spacing(1),
      top: 0,
    },
    videoProgressBar: {
      height: '4px',
      backgroundColor: theme.palette.primary.dark,
    },
    videoControlsLeft: {
      flex: 1,
      display: 'flex',
      flexWrap: 'nowrap',
    },
    videoControlsRight: {
      flex: 0,
      display: 'flex',
      flexWrap: 'nowrap',
    },
    commentsBlock: {
      margin: `${theme.spacing(2)}px 0`,
    },
    videoTitleBlock: {
      margin: `${theme.spacing(2)}px 0`,
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2),
      borderBottomWidth: '1px',
      borderBottomColor: theme.palette.divider,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'flex-end',
    },
    videoTitle: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    videoActions: {
      flex: 0,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    videoDescription: {
      paddingLeft: '42px',
      marginBottom: theme.spacing(2),
    },
  }),
);

interface VideoBlockProps {
  videos: IVideo[];
  setPage?(page: string): void;
}
const VideoBlock: React.FC<VideoBlockProps> = ({videos, children, setPage}: PropsWithChildren<VideoBlockProps>) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
      <div className={classes.gridList} >
        {videos.map(({hash, thumbnail, title, channel}: IVideo) => (
          <div key={hash} className={classes.gridTile} onClick={(e) => setPage && setPage('video') && (e.preventDefault())}>
            <img src={thumbnail} alt={title} />
            <div className={classes.gridTileOverlay}>
              <UserBlock onClick={(e) => setPage && setPage('channel') && (e.preventDefault())}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function videos(count: number) {
  return Array(count).fill(0).map((x, hash) => ({
    hash: (hash + 123456789).toString(36),
    thumbnail: `https://dummyimage.com/320x180/${Math.random().toString(16).substr(2,6)}/ffffff.png&text=Video+${Math.random().toString(36).substr(2, 5)}`,
    title: `Video ${Math.random().toString(36).substr(2)}`,
    channel: `Channel ${Math.random().toString(36).substr(2)}`,
  }))
}

const Network: React.FC = () => {
  const classes = useStyles();
  const bars = Array(Math.ceil(Math.random() * 20 + 10))
    .fill(0)
    .map(() => (Math.random() * 100 + 3).toFixed(0) + 'px');

  return (
    <div className={classes.network}>
      <div className={classes.networkStats}>
        <h4>NETWORK USAGE</h4>
        <div>3.5 MB/s CURRENT</div>
        <div>8.2 MB/s PEEK</div>
        <div>780 MB TOTAL</div>
      </div>
      {bars.map((height, index) => (
        <>
          <div key={`${index}-1`} className={classes.networkGraphBar} style={{height}}></div>
          <div key={`${index}-2`} className={classes.networkGraphBar} style={{height}}></div>
          <div key={`${index}-3`} className={classes.networkGraphBar} style={{height}}></div>
          <div key={`${index}-4`} className={classes.networkGraphBar} style={{height}}></div>
        </>
      ))}
    </div>
  )
};


interface DropZoneProps {
  setPage?(page: string): void;
}
const DropZone: React.FC<DropZoneProps> = ({setPage}: PropsWithChildren<DropZoneProps>) => {
  const classes = useStyles();
  return (
    <div className={classes.dropzone} onClick={() => setPage && setPage('upload')}>
      Drop video here or click me
    </div>
  )
};

const ChannelHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.channelBanner}>
        <img alt="Channel"
             src={`https://dummyimage.com/1900x200/${Math.random().toString(16).substr(2,6)}/ffffff.png&text=Channel+${Math.random().toString(36).substr(2, 5)}`}
             className={classes.channelBanner}
        />
      </div>
      <UserBlock>
        {!isMVP && (<Button variant="contained" color="primary">Subscribe</Button>)}
      </UserBlock>
    </>
  )
};

interface UserBlockProps {
  onClick?(event: any): void;
}
const UserBlock: React.FC<UserBlockProps> = ({children, onClick}: PropsWithChildren<UserBlockProps>) => {
  const classes = useStyles();
  return (
    <div className={classes.userBlock} onClick={onClick}>
      <div className={classes.userBlockAvatar}>
        <AccountCircleRounded fontSize="large" />
      </div>
      <div className={classes.userBlockTitle}>
        <div>Channel A</div>
        {!isMVP && (<small>3.5K subscribers</small>)}
      </div>
      {children}
    </div>
  )
};

const UploadBlock: React.FC = () => {
  const classes = useStyles();
  const [title, setTitle] = useState('Some local Video.mp4');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [category, setCategory] = useState('');

  const thumbnails = videos(3);
  const categories = Array(10).fill(0).map((x, index) => ({
    value: `category${index}`,
    label: `Category ${(index + 10).toString(36).toUpperCase()}`,
  }));

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        label="Title"
        className={classes.textField}
        value={title}
        onChange={({target}) => setTitle(target.value)}
        margin="normal"
        required
        fullWidth
        helperText="Converting... 80%"
      />
      <TextField
        disabled
        label="Video URL"
        className={classes.textField}
        value={`https://turrent-tube.com/t/${Math.random().toString(16).substr(2)}`}
        onChange={({target}) => setTitle(target.value)}
        margin="normal"
        fullWidth
        helperText="Click to copy!"
      />
      <TextField
        label="Description"
        multiline
        rowsMax="4"
        value={description}
        onChange={({target}) => setDescription(target.value)}
        className={classes.textField}
        margin="normal"
        fullWidth
      />
      {!isMVP && (<TextField
        select
        label="Category"
        className={classes.textField}
        value={category}
        onChange={({target}) => setCategory(target.value)}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        fullWidth
      >
        {categories.map(({value, label}) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>)}
      {!isMVP && (<TextField
        label="Tags"
        className={classes.textField}
        value={tags}
        onChange={({target}) => setTags(target.value)}
        margin="normal"
        fullWidth
        helperText="Comma separated"
      />)}

      {!isMVP && (<TextField
        select
        label="Language"
        className={classes.textField}
        value={category}
        onChange={({target}) => setCategory(target.value)}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        fullWidth
      >
        {categories.map(({value, label}) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>)}
      <div className={classes.root} style={{marginTop: '32px'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Thumbnail</FormLabel>
          <div className={classes.gridList} >
            <div className={classes.gridTile}>
              <Button>Custom...</Button>
            </div>
            {thumbnails.map(({hash, thumbnail, title}: IVideo) => (
              <div key={hash} className={classes.gridTile}>
                <img src={thumbnail} alt={title} />
              </div>
            ))}
          </div>
        </FormControl>
      </div>
    </form>
  );
};


interface VideoPageProps {
  setPage?(page: string): void;
}
const VideoPage: React.FC<VideoPageProps> = ({setPage}: VideoPageProps) => {
  const classes = useStyles();
  const related = videos(3);
  const comments = Array(10).fill(0).map((x, index) => ({
    user: `User ${(index + 10).toString(36).toUpperCase()}`,
    when: `${index + 2} days ago`,
    comment: `Comment goes here`,
  }));

  return (
    <>
      <div className={classes.videoContainer}>
        <div className={classes.videoPlayer}>
          <img alt="Video"
               width="100%"
               height="100%"
               src={`https://dummyimage.com/1280x720/${Math.random().toString(16).substr(2,6)}/ffffff.png&text=Video+${Math.random().toString(36).substr(2, 5)}`}
          />
        </div>
        <div className={classes.videoControls}>
          <div className={classes.videoProgressWrapper}>
            <div className={classes.videoProgressBar} style={{width: '85%'}}></div>
          </div>
          <div className={classes.videoControlsLeft}>
            <PlayArrowRounded/>
            {!isMVP && (<SkipNextRounded/>)}
            <VolumeUpRounded/>
            <div>10:15 / 12:35</div>
          </div>
          <div className={classes.videoControlsRight}>
            {!isMVP && (<SubtitlesRounded/>)}
            {!isMVP && (<BrandingWatermarkRounded/>)}
            {!isMVP && (<AirplayRounded/>)}
            <FullscreenRounded/>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={isMVP ? 12 : 8} >
          <div className={classes.videoTitleBlock}>
            <div className={classes.videoTitle}>
              <b>Video title goes here</b>
              <small>{!isMVP && ('25,454 views * ')}Oct 10, 2019</small>
            </div>
            <div className={classes.videoActions}>
              {!isMVP && (<ThumbUpAltRounded fontSize="small"/>)}
              {!isMVP && (<div>15   </div>)}
              {!isMVP && (<ThumbDownAltRounded fontSize="small"/>)}
              {!isMVP && (<div>1</div>)}
              <ShareRounded fontSize="small"/>
              <CloudDownloadRounded fontSize="small"/>
            </div>
          </div>
          <UserBlock onClick={() => setPage && setPage('channel')}>
            {!isMVP && (<Button variant="contained" color="primary">Subscribe</Button>)}
          </UserBlock>
          <div className={classes.videoDescription}>
            Description goes here
          </div>
          {!isMVP && (<VideoBlock videos={related} setPage={setPage}><h3>Recommended</h3></VideoBlock>)}
        </Grid>
        {!isMVP && (<Grid item xs={12} md={4}>
          <div className={classes.commentsBlock}>
            <b>Comments</b>
            <div className={classes.userBlock}>
              <div className={classes.userBlockAvatar}>
                <AccountCircleRounded fontSize="large" />
              </div>
              <div className={classes.userBlockTitle}>
                <TextField
                  placeholder="Add your public comment"
                  className={classes.textField}
                  fullWidth
                />
              </div>
            </div>
            {comments.map(({user, when, comment}) => (
              <div className={classes.userBlock}>
                <div className={classes.userBlockAvatar}>
                  <AccountCircleRounded />
                </div>
                <small className={classes.userBlockTitle}>
                  <div>{user} <small>{when}</small></div>
                  <i>{comment}</i>
                </small>
              </div>
            ))}
          </div>
        </Grid>)}
      </Grid>

    </>
  )
};

const App: React.FC = () => {
  const classes = useStyles();
  const [mvp, setIsMVP] = useState(false);
  const [page, setPage] = useState('home');

  isMVP = mvp;

  const trending = videos(5);
  const subscriptions = videos(6);
  const continueWatching = videos(2);
  const recommended = videos(8);
  const uploading = videos(1);
  const pending = videos(1);
  const published = videos(10);
  const downloading = videos(2);
  const seeding = videos(6);

  return (
    <MuiThemeProvider theme={theme}>
      <Typography>
        <div className="app" title={mvp ? 'MVP' : 'FULL'}>
          <Container fixed>
            <div className="main-menu">
              <div className="left">
                <VideocamRounded fontSize="large" onClick={() => setIsMVP(!mvp)}/>
                <Link onClick={() => setPage('home')}>Home</Link>
                <Link onClick={() => setPage('library')}>Library</Link>
                <Link onClick={() => setPage('my-channel')}>My Channel</Link>
              </div>
              <div className="right">
                <SearchRounded />
                {!isMVP && (<NotificationImportantRounded />)}
                <AccountCircleRounded fontSize="large" />
              </div>
            </div>
            {page === 'home' && (
              <>
                <VideoBlock videos={continueWatching} setPage={setPage}><h3>Continue Watching</h3></VideoBlock>
                {!isMVP && (<VideoBlock videos={subscriptions} setPage={setPage}><h3>Subscriptions</h3></VideoBlock>)}
                {!isMVP && (<VideoBlock videos={recommended} setPage={setPage}><h3>Recommended</h3></VideoBlock>)}
                <VideoBlock videos={trending} setPage={setPage}><h3>Trending</h3></VideoBlock>
              </>
            )}
            {page === 'library' && (
              <>
                <Network/>
                <VideoBlock videos={downloading} setPage={setPage}><h3>Downloads</h3></VideoBlock>
                <VideoBlock videos={seeding} setPage={setPage}><h3>Seeding</h3></VideoBlock>
              </>
            )}
            {page === 'my-channel' && (
              <>
                <DropZone setPage={setPage}/>
                <VideoBlock videos={uploading} setPage={setPage}><h3>Uploading</h3></VideoBlock>
                <VideoBlock videos={pending} setPage={setPage}><h3>Uploaded</h3></VideoBlock>
                <VideoBlock videos={published} setPage={setPage}><h3>Published</h3></VideoBlock>
              </>
            )}
            {page === 'channel' && (
              <>
                <ChannelHeader/>
                <div className={classes.videoDescription}>
                  Channel descriptions goes here
                </div>

                <VideoBlock videos={pending} setPage={setPage}><h3>Uploaded</h3></VideoBlock>
                {!isMVP && (<VideoBlock videos={published} setPage={setPage}><h3>Recommended</h3></VideoBlock>)}
              </>
            )}
            {page === 'upload' && (
              <UploadBlock/>
            )}
            {page === 'video' && (
              <VideoPage setPage={setPage}/>
            )}
          </Container>
        </div>
      </Typography>
    </MuiThemeProvider>
  );
}

export default App;
